import { useEffect, useState } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { useAtom } from 'jotai'

import { DataTable } from '@/components/sprint-past/Datatable'
import ClipboardCopyIcon from '@/components/icons/ClipboardCopyIcon'
import EyeOpenIcon from '@/components/icons/Eye'
import NotAllowedIcon from '@/components/icons/NotAllowedIcon'
import SortIcon from '@/components/icons/SortIcon'
import VerticalDot from '@/components/icons/VerticalDot'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Dialog, DialogContent } from '@/components/ui/dialog'

import { generateRandomAvatarColor, getNameAbbreviation } from '@/lib/utils'
import { getSprintPastList } from '@/services/api-client'
import TicketDetails from '@/components/sprint-past/ticket-details'
import { TicketPastAtom } from '@/atom/ticket'
import { TicketPast } from '@/types/ITicketPast'

import { toast } from 'sonner'

type TicketsListType = {
  id: string
  subject: string
  epic: string
  filterKey?: string
  startDate: string
  endDate: string
  priority: { urlIcon: string; name: string }
  assignTo: string
  id_subject: string
  actions: string
}

function extractString(input: string = ''): string {
  const regex = /Module\s+"([^"]+)"/
  const match = input.match(regex)

  if (match) {
    return match[1]
  } else {
    return input
  }
}

export default function SprintPast() {
  const columns: ColumnDef<TicketsListType>[] = [
    {
      accessorKey: 'id_subject',
      header: 'Clé - Sujet',
      cell: ({ row }) => {
        return (
          <span className="text-primary-dark text-sm font-semibold">
            {row.getValue('id_subject')}
          </span>
        )
      },
    },
    {
      accessorKey: 'id',
      header: 'Clé',
      cell: ({ row }) => {
        return (
          <span className="text-primary-dark text-sm font-semibold">
            {row.getValue('id')}
          </span>
        )
      },
    },
    {
      accessorKey: 'subject',
      header: 'Sujet',
      cell: ({ row }) => {
        return (
          <div
            onClick={() => setCurrentTicketKey(row.getValue('id'))}
            className="hover:cursor-pointer hover:underline transition-all text-primary-dark w-[600px] overflow-hidden text-ellipsis text-nowrap text-sm font-medium"
          >
            {row.getValue('subject')}
          </div>
        )
      },
    },
    {
      accessorKey: 'epic',
      header: ({ column }) => {
        return (
          <div className="flex items-center gap-x-2">
            Epic
            <SortIcon
              className="hover:cursor-pointer"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            />
          </div>
        )
      },
      cell: ({ row }) => {
        const { bgColor } = generateRandomAvatarColor(row.getValue('epic'))
        const { bgColor: bgColorTransparency } = generateRandomAvatarColor(
          row.getValue('epic'),
          0.15
        )
        let content
        if (row.getValue('epic')) {
          content = (
            <span className="text-primary-dark text-sm font-medium flex items-center gap-x-2">
              <span
                className="flex justify-center items-center text-sm font-medium px-3 py-1 rounded-sm"
                style={{ backgroundColor: bgColorTransparency, color: bgColor }}
              >
                {row.getValue('epic')}
              </span>
            </span>
          )
        } else {
          content = <NotAllowedIcon />
        }

        return content
      },
    },
    {
      accessorKey: 'priority',
      header: ({ column }) => {
        return (
          <div className="flex items-center gap-x-2">
            Priorité
            <SortIcon
              className="hover:cursor-pointer"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            />
          </div>
        )
      },
      cell: ({ row }) => {
        return (
          <img
            className="w-8 h-8"
            title={(row.getValue('priority') as any).name}
            src={(row.getValue('priority') as any).urlIcon.split(' ')[1]}
          />
        )
      },
    },
    {
      accessorKey: 'assignTo',
      header: ({ column }) => {
        return (
          <div className="flex items-center gap-x-2">
            Assigné à
            <SortIcon
              className="hover:cursor-pointer"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            />
          </div>
        )
      },
      cell: ({ row }) => {
        const { bgColor, textColor } = generateRandomAvatarColor(
          row.getValue('assignTo')
        )
        const firstname = (row.getValue('assignTo') as string).split(' ')[0]
        return (
          <span className="text-primary-dark text-sm font-medium flex items-center gap-x-2">
            <span
              className="flex justify-center items-center rounded-full w-7 h-7 text-xs font-medium"
              style={{ backgroundColor: bgColor, color: textColor }}
            >
              {getNameAbbreviation(row.getValue('assignTo'))}
            </span>
            {firstname}
          </span>
        )
      },
    },
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="ghost"
                className="h-8 w-8 p-0 border border-neutral-dark rounded-lg"
              >
                <span className="sr-only">Open menu</span>
                <VerticalDot />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="border-neutral-dark w-52 shadow-sm p-4 [&>div]:py-1 [&>div]:px-2 flex flex-col gap-2"
            >
              <DropdownMenuItem
                onClick={() => setCurrentTicketKey(row.getValue('id'))}
              >
                <span className="flex gap-2 text-base font-normal items-center">
                  <EyeOpenIcon /> Voir
                </span>
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${window.location.origin}/ticket/${row.getValue('id')}`
                  )
                  toast('Lien copié dans le presse-papiers')
                }}
              >
                <span className="flex gap-2 text-base font-normal items-center">
                  <ClipboardCopyIcon /> Copier le lien
                </span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )
      },
    },
  ]

  const [_, setTicketAtom] = useAtom(TicketPastAtom)
  const [currentTicketKey, setCurrentTicketKey] = useState<string>()
  const [allTicketsList, setAllTicketsList] = useState<TicketsListType[]>([])
  const [ticketsList, setTicketsList] = useState<TicketsListType[]>([])
  const [apiResult, setApiResut] = useState<TicketPast[]>()
  const [index, setIndex] = useState<number>(0)

  useEffect(() => {
    const filteredTicket = filterCurrentTicket(currentTicketKey as string)
    setTicketAtom(filteredTicket as TicketPast)
  }, [currentTicketKey])

  async function getTickets() {
    const { data } = await getSprintPastList('CLI')
    return Promise.resolve(data)
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await getTickets()
        setApiResut(result)
        setAllTicketsList((prev) => [
          ...prev,
          ...result.map((item: any) => ({
            id: item.key,
            subject: item.fields.summary,
            epic: extractString(item.fields.parent?.fields?.summary),
            filterKey: item.fields.customfield_10020[0].name,
            startDate: item.fields.customfield_10020[0].startDate,
            endDate: item.fields.customfield_10020[0].endDate,
            priority: {
              urlIcon: `${item.fields.priority.id} ${item.fields.priority.iconUrl}`,
              name: item.fields.priority.name,
            },
            assignTo: item.fields.reporter.displayName,
            id_subject: `${item.key} ${item.fields.summary}`,
            action: item.fields.self,
          })),
        ])
      } catch (error) {
        throw new Error('Unexpected error occured')
      }
    }

    fetchData()
  }, [])

  function filterCurrentTicket(key: string): TicketPast | undefined {
    return apiResult?.find((item) => key === item.key)
  }
  const uniqueFilterKeys = allTicketsList.reduce<string[]>((acc, curr) => {
    const filterKey = curr.filterKey ?? ''
    if (!acc.includes(filterKey)) {
      acc.push(filterKey)
    }
    return acc
  }, [])

  useEffect(() => {
    const defaultTicketValue = allTicketsList.filter(
      (item) => item.filterKey === uniqueFilterKeys[uniqueFilterKeys.length - 1]
    )
    setTicketsList([...defaultTicketValue])
  }, [allTicketsList])

  function filterNext() {
    if (index < uniqueFilterKeys.length - 1) {
      setIndex((prev) => prev + 1)
      const data = allTicketsList.filter(
        (item) => item.filterKey === uniqueFilterKeys[index]
      )
      setTicketsList(data)
    }
  }

  function filterPrevious() {
    if (index > 0) {
      setIndex((prev) => prev - 1)
      const data = allTicketsList.filter(
        (item) => item.filterKey === uniqueFilterKeys[index]
      )
      setTicketsList(data)
    }
  }

  return (
    <>
      <DataTable
        infos={{
          filterKey: ticketsList[0]?.filterKey || '',
          startDate: ticketsList[0]?.startDate || '',
          endDate: ticketsList[0]?.endDate || '',
        }}
        handleNextSprint={filterNext}
        handlePreviousSprint={filterPrevious}
        columns={columns}
        data={ticketsList}
      />
      <Dialog
        open={!!currentTicketKey}
        onOpenChange={() => setCurrentTicketKey(undefined)}
      >
        <DialogContent className="max-w-[768px]">
          <TicketDetails />
        </DialogContent>
      </Dialog>
    </>
  )
}
