import { DialogTitle } from '@/components/ui/dialog'
import ActionsTop from './ActionsTop'
import { DialogDescription } from '@radix-ui/react-dialog'
import { useAtom } from 'jotai'
import { TicketPastAtom } from '@/atom/ticket'
import NotAllowedIcon from '@/components/icons/NotAllowedIcon'
import clsx from 'clsx'
import {
  formatDateTime,
  generateRandomAvatarColor,
  getNameAbbreviation,
  JiraTextFormatAdapter,
} from '@/lib/utils'
import { getTicketDetails } from '@/services/api-client'
import { useEffect, useState } from 'react'
import { LoaderCircleIcon } from 'lucide-react'
import MarkdownRenderer from '@/components/markdown'

function CustomNameAvatar({ rawValue }: { rawValue: string }) {
  if (rawValue) {
    const { bgColor, textColor } = generateRandomAvatarColor(rawValue)
    const firstname = rawValue.split(' ')[0]
    return (
      <span className="text-primary-dark flex items-center gap-x-2 font-normal text-base">
        <span
          className="flex justify-center items-center rounded-full w-7 h-7 text-xs font-medium"
          style={{ backgroundColor: bgColor, color: textColor }}
        >
          {getNameAbbreviation(rawValue)}
        </span>
        {firstname}
      </span>
    )
  } else {
    return null
  }
}

export default function TicketDetails() {
  const [ticketAtom] = useAtom(TicketPastAtom)
  const [isLoadDetails, setLoadDetails] = useState(false)
  const [description, setDescription] = useState(null)
  async function getDescription(ticketKey: string) {
    try {
      setLoadDetails(true)
      const { data } = await getTicketDetails(ticketKey)
      setDescription(data['fields']['description'])
    } catch (error) {
      throw new Error('Unexpected error occured')
    } finally {
      setLoadDetails(false)
    }
  }

  useEffect(() => {
    ticketAtom?.key && getDescription(ticketAtom?.key as string)
  }, [ticketAtom])
  return (
    <div>
      {/* dialog top actions */}
      <ActionsTop ticketKey={ticketAtom?.key as string} />
      {/* dialog main content */}
      <div className="pt-8 pb-6">
        {/* dialog title */}
        <DialogDescription />
        <DialogTitle />
        <div className="w-full grid grid-cols-4">
          <h1 className="text-primary-dark font-semibold w-full max-w-[554px] col-span-3">
            {ticketAtom?.fields.summary}
          </h1>
          <span className="text-[#717171] font-semibold w-full text-end">
            {ticketAtom?.key}
          </span>
        </div>
      </div>
      <ul className="pb-6 flex [&>] flex-col gap-y-4 [&>li]:flex [&>li]:w-full [&>li]:justify-between max-w-72 [&>li>span:first-child]:text-base [&>li>span:first-child]:font-normal [&>li>span:first-child]:text-[#717171]">
        <li>
          <span>Sprint</span>
          <NotAllowedIcon />
        </li>
        <li>
          <span>Statut</span>{' '}
          <span
            className={clsx(
              'py-1 px-3 font-semibold rounded-md uppercase',
              `text-${ticketAtom?.fields.status.statusCategory.colorName}-700`,
              `bg-${ticketAtom?.fields.status.statusCategory.colorName}-700/10`
            )}
          >
            {ticketAtom?.fields.status.statusCategory.name}
          </span>
        </li>
        <li>
          <span>Priorité</span>{' '}
          <div className="flex items-center gap-x-2">
            <img
              src={ticketAtom?.fields.priority.iconUrl}
              className="w-6 h-6"
            />
            <span className="font-normal text-primary-dark text-base">
              {ticketAtom?.fields.priority.name}
            </span>
          </div>
        </li>
        <li>
          <span>Assigné à</span>{' '}
          <CustomNameAvatar
            rawValue={ticketAtom?.fields.assignee.displayName as string}
          />
        </li>
        <li>
          <span>Date de création</span>{' '}
          <span className="text-primary-dark">
            {formatDateTime(new Date(ticketAtom?.fields.created as string))}
          </span>
        </li>
      </ul>
      {/* separator */}
      <div className="w-full border-b border-b-neutral-dark absolute left-1/2 -translate-x-1/2" />

      {/* description */}
      <div className="pt-6">
        <div className="p-6">
          {isLoadDetails && !description ? (
            <div className="w-full flex justify-center">
              <LoaderCircleIcon className="animate-spin" />
            </div>
          ) : (
            <div className="text-primary-dark font-normal text-base max-h-80 overflow-y-auto">
              <MarkdownRenderer
                content={JiraTextFormatAdapter(description || '')}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
