import ChevronLeft from '@/components/icons/ChevronLeft'
import ChevronRight from '@/components/icons/ChevronRight'
import CrossIcon from '@/components/icons/CrossIcon'
import ExtendIcon from '@/components/icons/ExtendIcon'
import { DialogClose } from '@/components/ui/dialog'
import { toast } from 'sonner'

export default function ActionsTop({ticketKey}: {ticketKey: string}) {
  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-x-2">
        <div className="opacity-40 text-sm transition-opacity hover:opacity-100 data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
          <ChevronLeft />
        </div>
        <div className="opacity-40 text-sm transition-opacity hover:opacity-100 data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
          <ChevronRight />
        </div>
      </div>
      <div className="flex items-center gap-x-2">
        <div
          onClick={() => {
            navigator.clipboard.writeText(
              `${window.location.origin}/ticket/${ticketKey}`
            )
            toast('Lien copié dans le presse-papiers')
          }}
          className="hover:cursor-pointer opacity-70 text-sm transition-opacity hover:opacity-100 data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
        >
          Copier le lien
        </div>
        <div className=" hover:cursor-pointer opacity-70 transition-opacity hover:opacity-100 data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
          <ExtendIcon className="h-5 w-5" />
        </div>
        <DialogClose asChild>
          <div className="hover:cursor-pointer opacity-70 transition-opacity hover:opacity-100 data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
            <CrossIcon className="h-5 w-5" />
          </div>
        </DialogClose>
      </div>
    </div>
  )
}
