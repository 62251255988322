import ColoredPlusIcon from '@/components/icons/ColoredPlusIcon'
import CrossIcon from '@/components/icons/CrossIcon'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import SwitchButton from '@/components/ui/switch-button'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

import {
  CATEGORY_LIST,
  TOAST_ERROR_MSG,
} from '@/configs/constants'
import { Textarea } from '@/components/ui/textarea'
import { Separator } from '@/components/ui/separator'
import { useState } from 'react'
import { createRequest } from '@/services/api-client'
import { useToast } from '@/hooks/use-toast'
import CancelModalContent from '@/components/modal/cancel-modal-content'

const formSchema = z.object({
  title: z
    .string()
    .min(1, {
      message: 'Le titre doit contenir au moin 1 caractère.',
    })
    .max(128, {
      message: 'Le titre doit pas depasser 128 caractères.',
    }),
  summary: z.string().min(1, {
    message: 'La description doit contenir au moin 1 caractère.',
  }),
  category: z.string().min(1).min(1, 'Ajouter au moin une categorie.'),
  environment: z.boolean(),
})

export default function RequestForm({ closeModal }: { closeModal: Function }) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: '',
      summary: '',
      category: CATEGORY_LIST[0].value,
      environment: true, // true means "Production" and false => "Pre-prod"
    },
  })

  const { toast } = useToast()

  const [isLoading, setLoading] = useState(false)
  const [isOpenCancelCreaction, setIsOpenCancelCreaction] = useState(false)

  const convertEnvField = (value: boolean) => {
    return value ? 'Production' : 'Pre-Prod'
  }

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const payload = {
      ...values,
      environment: convertEnvField(values.environment),
    }
    try {
      setLoading(true)
      await createRequest(payload)
      closeModal(values.title)
    } catch (error) {
      toast(TOAST_ERROR_MSG)
      throw new Error('Unexpected error occured')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <DialogDescription />
      <DialogTitle />
      <DialogClose className="absolute top-5 right-9 hover:cursor-pointer opacity-70 transition-opacity hover:opacity-100 data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
        <CrossIcon className="h-5 w-5" />
      </DialogClose>
      <div>
        <div className="flex flex-col justify-center items-center gap-y-2">
          <div className="p-1 rounded-md bg-primary-light/10 w-auto">
            <ColoredPlusIcon />
          </div>
          <span className="text-base font-medium text-primary-dark">
            Ajout d'une demande
          </span>
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="pt-6 px-24 space-y-8 [&>div]:!mt-4">
              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-primary-dark">Titre</FormLabel>
                    <FormControl>
                      <Input placeholder="Ajoutez un titre" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="category"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-primary-dark">
                      Type de demande
                    </FormLabel>
                    <Select onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Tâche" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {CATEGORY_LIST.map((item) => (
                          <SelectItem key={item.value} value={item.value}>
                            {item.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="environment"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-primary-dark">
                      Environnement concerné
                    </FormLabel>
                    <FormControl>
                      <SwitchButton
                        leftValue="Production"
                        rightValue="Pre-prod"
                        onChange={field.onChange}
                        light
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="summary"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-primary-dark">
                      Description
                    </FormLabel>
                    <FormControl>
                      <Textarea
                        {...field}
                        placeholder="Ajoutez les spécifications de la demande ..."
                        rows={7}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <Separator className="absolute left-0 w-full" />
            <div className="flex justify-end items-center h-20 ">
              <Button
                variant={'ghost'}
                className="text-neutral-darker hover:bg-transparent"
                onClick={() => setIsOpenCancelCreaction(true)}
                type="button"
              >
                Annuler
              </Button>
              <Button type="submit" isLoading={isLoading}>
                Créer
              </Button>
            </div>
          </form>
        </Form>
      </div>

      {/* CONFIRMATION CLOSE DIALOG */}
      <Dialog
        open={!!isOpenCancelCreaction}
        onOpenChange={() => setIsOpenCancelCreaction(false)}
      >
        <DialogContent className="max-w-[480px]">
          <CancelModalContent onCancel={() => closeModal()} />
        </DialogContent>
      </Dialog>
    </div>
  )
}
