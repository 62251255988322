import axiosClient from '@/configs/axios'

// @TO DO group request by features

export function getAllTicketsList(projectKey: string) {
  return axiosClient.get(`/tickets/${projectKey}?status=Backlog`)
}

export function getTicketDetails(ticketKey: string) {
  return axiosClient.get(`/ticket/${ticketKey}`)
}

export function getDashboardData(projectKey: string) {
  return axiosClient.get(`/dashboard/${projectKey}`)
}

export function getSprintPastList(projectKey: string) {
  return axiosClient.get(`/tickets/${projectKey}?status=Passed`)
}

export function getProjectRequest(
  projectID: string = '56c7588b-e7f1-4f39-93a7-3f55582debfb'
) {
  return axiosClient.get(`/project_requests/${projectID}`)
}

export function createRequest(
  data: {
    title: string
    summary: string
    category: string
    environment: string
  },
  /**
   * @TODO handle dynamic project id
   */
  project: string = '56c7588b-e7f1-4f39-93a7-3f55582debfb'
) {
  return axiosClient.post(`/requests`, { ...data, project })
}

export function editRequest(
  data: {
    title: string
    summary: string
    category: string
    environment: string
    id: string
  },
  /**
   * @TODO handle dynamic project id
   */
  project: string = '56c7588b-e7f1-4f39-93a7-3f55582debfb'
) {
  const { id, ...payload } = data
  return axiosClient.put(`/requests/${id}`, { ...payload, project })
}

export function getRequestDetails(requestID: string) {
  return axiosClient.get(`/requests/${requestID}`)
}

export function getRequestComments(requestID: string) {
  return axiosClient.get(`/requests/${requestID}/comments`)
}

export function getTicketToTest(projectKey: string) {
  return axiosClient.get(`/tickets/${projectKey}?status=Client+Testing`)
}

export function getTotalTicketToTest(projectKey: string) {
  return axiosClient.get(`/tickets/${projectKey}?status=Client+Testing&count=1`)
}
