import MarkdownRenderer from '@/components/markdown'
import AttachmentsIcon from '@/components/icons/Attachments'
import { formatDateTime, JiraTextFormatAdapter } from '@/lib/utils'
import { getTicketDetails } from '@/services/api-client'
import { LoaderCircleIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { toast } from 'sonner'
interface ITicketSummaryDetailProps {
  title: string
  priorityStatus: string
  ticketKey: string
  date: Date
}

const prio: { [key: string]: string } = {
  Highest: 'Très Élevée',
  High: 'Élevée',
  Medium: 'Moyenne',
  Low: 'Basse',
  Lowest: 'Très Basse',
}

const prioBgColor: { [key: string]: string } = {
  Highest: 'bg-red-600/10',
  High: 'bg-orange-500/10',
  Medium: 'bg-yellow/10',
  Low: 'bg-blue-300/10',
  Lowest: 'bg-green-400/10',
}

const prioTxtColor: { [key: string]: string } = {
  Highest: 'text-red-600',
  High: 'text-orange-500',
  Medium: 'text-third',
  Low: 'text-blue-300',
  Lowest: 'text-green-400',
}

const TicketSummaryDetail: React.FC<ITicketSummaryDetailProps> = ({
  title,
  priorityStatus,
  ticketKey,
  date,
}) => {
  const [isLoadDetails, setLoadDetails] = useState(false)
  const [description, setDescription] = useState(null)

  async function getDescription(ticketKey: string) {
    try {
      setLoadDetails(true)
      const { data } = await getTicketDetails(ticketKey)
      setDescription(data['fields']['description'])
    } catch (error) {
      throw new Error('Unexpected error occured')
    } finally {
      setLoadDetails(false)
    }
  }
  useEffect(() => {
    ticketKey && getDescription(ticketKey)
  }, [ticketKey])

  return (
    <div className="bg-white col-span-4  rounded-sm border border-neutral-dark ">
      <div className="flex justify-between px-8 py-6 border-b border-neutral-dark">
        <div>
          <h2 className="text-primary-dark text-2xl">{title}</h2>
          <span
            className={`rounded-full py-1 px-3   mt-3 inline-block  ${prioTxtColor[priorityStatus as string]} ${prioBgColor[priorityStatus as string]}`}
          >
            Priorité {prio[priorityStatus]?.toLowerCase()}
          </span>
        </div>
        <div className="flex flex-col gap-2">
          <div className="inline-flex items-center gap-2 [&_path]:stroke-primary-light">
            <span className="block text-primary-light font-semibold text-sm">
              {ticketKey}
            </span>
            <div
              className="cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.origin}/ticket/${ticketKey}`
                )
                toast('Lien copié dans le presse-papiers')
              }}
            >
              <AttachmentsIcon />
            </div>
          </div>
          <span className="block text-neutral-darker text-sm">
            {formatDateTime(date)}
          </span>
        </div>
      </div>
      <div className="p-16">
        {isLoadDetails && !description ? (
          <div className="w-full flex justify-center">
            <LoaderCircleIcon className="animate-spin" />
          </div>
        ) : (
          <div className="text-primary-dark font-normal text-base max-h-96 overflow-y-auto">
            <MarkdownRenderer
              content={JiraTextFormatAdapter(
                description || 'Pas de description'
              )}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default TicketSummaryDetail
