import UsefulLinkItem from '@/components/dashboard/UsefulLinkItem'

interface Link {
  icon: string
  name: string
  url: string
  category?: string
}
const defaultLinkValue = [
  {
    category: 'GITHUB',
    name: 'Github',
    url: 'https://github.com/Hello-Pomelo/jira-client-view',
    icon: 'https://my-pomelo.fra1.digitaloceanspaces.com/assets/github.png',
  },
]
const UsefulLinks: React.FC<{ links: Link[] }> = ({
  links = defaultLinkValue,
}) => {
  return (
    <ul className="flex flex-col gap-4">
      {links.map((link, index) => (
        <li key={link.url}>
          <UsefulLinkItem
            urlImage={link.icon}
            name={link.name}
            link={link.url}
          />
        </li>
      ))}
    </ul>
  )
}

export default UsefulLinks
