import { useEffect, useState } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { useAtom } from 'jotai'

import { DataTable } from '@/components/backlog/DataTable'
import ClipboardCopyIcon from '@/components/icons/ClipboardCopyIcon'
import EyeOpenIcon from '@/components/icons/Eye'
import NotAllowedIcon from '@/components/icons/NotAllowedIcon'
import SortIcon from '@/components/icons/SortIcon'
import VerticalDot from '@/components/icons/VerticalDot'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Dialog, DialogContent } from '@/components/ui/dialog'

import { generateRandomAvatarColor, getNameAbbreviation } from '@/lib/utils'
import { getAllTicketsList } from '@/services/api-client'
import TicketDetails from '@/components/backlog/ticket-details'
import { TicketAtom } from '@/atom/ticket'
import { TicketType } from '@/types/ITicket'

import { toast } from 'sonner'

type TicketsListType = {
  id: string
  subject: string
  sprint: string
  priority: { urlIcon: string; name: string }
  assignTo: string
  id_subject: string
  actions: string
}

export default function Backlog() {
  const columns: ColumnDef<TicketsListType>[] = [
    {
      accessorKey: 'id_subject',
      header: 'Clé - Sujet',
      cell: ({ row }) => {
        return (
          <span className="text-primary-dark text-sm font-semibold">
            {row.getValue('id_subject')}
          </span>
        )
      },
    },
    {
      accessorKey: 'id',
      header: 'Clé',
      cell: ({ row }) => {
        return (
          <span className="text-primary-dark text-sm font-semibold">
            {row.getValue('id')}
          </span>
        )
      },
    },
    {
      accessorKey: 'subject',
      header: 'Sujet',
      cell: ({ row }) => {
        return (
          <div
            onClick={() => setCurrentTicketKey(row.getValue('id'))}
            className="hover:cursor-pointer hover:underline transition-all text-primary-dark w-[600px] overflow-hidden text-ellipsis text-nowrap text-sm font-medium"
          >
            {row.getValue('subject')}
          </div>
        )
      },
    },
    {
      accessorKey: 'sprint',
      header: ({ column }) => {
        return (
          <div className="flex items-center gap-x-2">
            Sprint
            <SortIcon
              className="hover:cursor-pointer"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            />
          </div>
        )
      },
      cell: () => {
        return <NotAllowedIcon />
      },
    },
    {
      accessorKey: 'priority',
      header: ({ column }) => {
        return (
          <div className="flex items-center gap-x-2">
            Priorité
            <SortIcon
              className="hover:cursor-pointer"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            />
          </div>
        )
      },
      cell: ({ row }) => {
        return (
          <img
            className="w-8 h-8"
            title={(row.getValue('priority') as any).name}
            src={(row.getValue('priority') as any).urlIcon.split(' ')[1]}
          />
        )
      },
    },
    {
      accessorKey: 'assignTo',
      header: ({ column }) => {
        return (
          <div className="flex items-center gap-x-2">
            Assigné à
            <SortIcon
              className="hover:cursor-pointer"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            />
          </div>
        )
      },
      cell: ({ row }) => {
        const { bgColor, textColor } = generateRandomAvatarColor(
          row.getValue('assignTo')
        )
        const firstname = (row.getValue('assignTo') as string).split(' ')[0]
        return (
          <span className="text-primary-dark text-sm font-medium flex items-center gap-x-2">
            <span
              className="flex justify-center items-center rounded-full w-7 h-7 text-xs font-medium"
              style={{ backgroundColor: bgColor, color: textColor }}
            >
              {row.getValue('assignTo') ? (
                getNameAbbreviation(row.getValue('assignTo'))
              ) : (
                <NotAllowedIcon />
              )}
            </span>
            {firstname}
          </span>
        )
      },
    },
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="ghost"
                className="h-8 w-8 p-0 border border-neutral-dark rounded-lg"
              >
                <span className="sr-only">Open menu</span>
                <VerticalDot />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="border-neutral-dark w-52 shadow-sm p-4 [&>div]:py-1 [&>div]:px-2 flex flex-col gap-2"
            >
              <DropdownMenuItem
                onClick={() => setCurrentTicketKey(row.getValue('id'))}
              >
                <span className="flex gap-2 text-base font-normal items-center">
                  <EyeOpenIcon /> Voir
                </span>
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${window.location.origin}/ticket/${row.getValue('id')}`
                  )
                  toast('Lien copié dans le presse-papiers')
                }}
              >
                <span className="flex gap-2 text-base font-normal items-center">
                  <ClipboardCopyIcon /> Copier le lien
                </span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )
      },
    },
  ]
  const [_, setTicketAtom] = useAtom(TicketAtom)
  const [currentTicketKey, setCurrentTicketKey] = useState<string>()
  const [ticketsList, setTicketsList] = useState<TicketsListType[]>([])
  const [apiResult, setApiResut] = useState<TicketType[]>()

  useEffect(() => {
    const filteredTicket = filterCurrentTicket(currentTicketKey as string)
    setTicketAtom(filteredTicket as TicketType)
  }, [currentTicketKey])

  async function getTickets() {
    const { data } = await getAllTicketsList('CLI')
    return Promise.resolve(data)
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await getTickets()
        setApiResut(result)
        setTicketsList((prev) => [
          ...prev,
          ...result.map((item: any) => ({
            id: item.key,
            subject: item.fields.summary,
            sprint: 'Sprint 3',
            priority: {
              urlIcon: `${item.fields.priority.id} ${item.fields.priority.iconUrl}`,
              name: item.fields.priority.name,
            },
            assignTo: item.fields.assignee?.displayName || '',
            id_subject: `${item.key} ${item.fields.summary}`,
            action: item.fields.self,
          })),
        ])
      } catch (error) {
        throw new Error('Unexpected error occured')
      }
    }

    fetchData()
  }, [])

  function filterCurrentTicket(key: string): TicketType | undefined {
    return apiResult?.find((item) => key === item.key)
  }

  return (
    <>
      <DataTable columns={columns} data={ticketsList} />
      <Dialog
        open={!!currentTicketKey}
        onOpenChange={() => setCurrentTicketKey(undefined)}
      >
        <DialogContent className="max-w-[768px]">
          <TicketDetails />
        </DialogContent>
      </Dialog>
    </>
  )
}
