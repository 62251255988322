import SideBar from '@/components/navigation/SideBar'
import TopBar from '@/components/navigation/TopBar'
import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

import Cookies from 'js-cookie'
import { Toaster as Sonner } from '@/components/ui/sonner'
import { Toaster } from '@/components/ui/toaster'
import { ScrollArea } from '@/components/ui/scroll-area'
import clsx from 'clsx'

export default function DashBoardLayout() {
  const [adjustPadding, setAdjustPadding] = useState(false)
  useEffect(() => {
    const apiToken = Cookies.get('apitoken')
    if (!apiToken) {
      window.location.href = '/auth/login/'
    }
    return () => {
      setAdjustPadding(false)
    }
  }, [])
  const handlePaddingAdaptation = (value: boolean) => {
    setAdjustPadding(value)
  }
  return (
    <>
      <div className="w-full h-[calc(100vh-3rem)]">
        <TopBar />
        <div className="flex w-full h-full">
          <SideBar />
          <ScrollArea className={clsx('bg-neutral-light w-full px-6 pt-5', adjustPadding ? 'pr-0' : '')}>
            <Outlet context={handlePaddingAdaptation} />
          </ScrollArea>
        </div>
      </div>
      <Sonner
        position="bottom-center"
        className="bg-[#171717] shadow-none text-white [&_li]:w-auto"
      />
      <Toaster />
    </>
  )
}
