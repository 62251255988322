import RequestDetails from '@/components/request/request-details.tsx'
import ActionsTopFullscreen from '../ActionTop'
import { useParams, useOutletContext } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getRequestDetails } from '@/services/api-client'
import { useAtom } from 'jotai'
import { RequestAtom } from '@/atom/request'
import Comments from '@/components/commments/Comments'

export default function FullScreenRequest() {
  const [requestAtom, setRequestAtom] = useAtom(RequestAtom)
  const [isLoadingPage, setLoadingPage] = useState(false)
  const { id } = useParams()
  const ajustParentPadding: any = useOutletContext()

  useEffect(() => {
    fetchRequestDetails()
    ajustParentPadding(true)
    return () => {
      ajustParentPadding(false)
    }
  }, [id])

  const fetchRequestDetails = async () => {
    setLoadingPage(true)
    try {
      const { data } = await getRequestDetails(id as string)
      setRequestAtom(data)
    } catch (error) {
      throw new Error('Unexpected error occured')
    } finally {
      setLoadingPage(false)
    }
  }
  if (isLoadingPage) {
    return <div>Loading</div>
  }
  return (
    <div className='grid grid-cols-3 gap-x-6 h-full'>
      <ActionsTopFullscreen ticketKey={id as string} />
      <div className="relative col-span-2">
        <div className="mt-20 px-8 bg-white rounded border border-neutral-dark">
          <div className="">
            <RequestDetails isFullScreen />
          </div>
        </div>
      </div>
      <div className="relative pt-20 px-8 h-[calc(100vh-4.25rem)] bg-white rounded border border-neutral-dark">
        <Comments requestId={requestAtom?.id || ''} />
      </div>
    </div>
  )
}
