import { useEffect, useState } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { useAtom } from 'jotai'
import {
  formatDateTime,
  generateRandomAvatarColor,
  getNameAbbreviation,
} from '@/lib/utils'
import { RequestAtom } from '@/atom/request'
import { getProjectRequest } from '@/services/api-client'
import { toast } from 'sonner'
import { useNavigate } from 'react-router-dom'
import { ProjectRequest as IProjectRequest } from '@/types/IProjectRequest'

import { DataTable } from '@/components/request/DataTable'
import ClipboardCopyIcon from '@/components/icons/ClipboardCopyIcon'
import EyeOpenIcon from '@/components/icons/Eye'
import NotAllowedIcon from '@/components/icons/NotAllowedIcon'
import SortIcon from '@/components/icons/SortIcon'
import VerticalDot from '@/components/icons/VerticalDot'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Dialog, DialogContent } from '@/components/ui/dialog'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import RequestDetails from '@/components/request/request-details.tsx'
import PenIcon from '@/components/icons/PenIcon'
import EditRequestForm from '@/components/request/request-form/edit-form'

type RequestListType = {
  id: string
  subject: string
  creationDate: string
  status: string
  author: string
  id_subject: string
  actions: string
}

export default function Request() {
  const columns: ColumnDef<RequestListType>[] = [
    {
      accessorKey: 'id_subject',
      header: 'Clé - Sujet',
      cell: ({ row }) => {
        return (
          <span className="text-primary-dark text-sm font-semibold">
            {row.getValue('id_subject')}
          </span>
        )
      },
    },
    {
      accessorKey: 'subject',
      cell: ({ row }) => {
        const subject = (row.getValue('subject') as string).split(
          '__split__'
        )[0]
        const category = (row.getValue('subject') as string).split(
          '__split__'
        )[1]
        const { bgColor } = generateRandomAvatarColor(
          category + 'category_color'
        )
        return (
          <div className="flex flex-col gap-y-2">
            <div
              onClick={() => setCurrentRequestKey(row.getValue('action'))}
              className="transition-all flex gap-x-2 items-center group text-primary-dark w-[600px] hover:cursor-pointer hover:underline overflow-hidden text-ellipsis text-sm font-medium"
            >
              <span className="line-clamp-2 overflow-hidden">{subject}</span>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <EyeOpenIcon className="hidden transition-all group-hover:block" />
                  </TooltipTrigger>
                  <TooltipContent side="bottom" sideOffset={10}>
                    <p className="relative">
                      <span className="" /> Voir le ticket
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="flex items-baseline gap-x-1">
              <div
                className="w-3 h-3 rounded-sm"
                style={{ backgroundColor: bgColor }}
              />
              <span className="text-sm text-neutral-darker/80 font-normal">
                {category}
              </span>
            </div>
          </div>
        )
      },
      header: ({ column }) => {
        return (
          <div className="flex items-center gap-x-2">
            Sujet
            <SortIcon
              className="hover:cursor-pointer"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            />
          </div>
        )
      },
    },
    {
      accessorKey: 'author',
      header: ({ column }) => {
        return (
          <div className="flex items-center gap-x-2">
            Auteur
            <SortIcon
              className="hover:cursor-pointer"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            />
          </div>
        )
      },
      cell: ({ row }) => {
        /**
         * concat value on one string will be needed for sorting system
         */
        const name = `${(row.getValue('author') as string).split(' ')[0]} ${(row.getValue('author') as string).split(' ')[1]}`
        const email = `${(row.getValue('author') as string).split(' ')[2]}`
        const { bgColor, textColor } = generateRandomAvatarColor(name)
        return (
          <span className="text-primary-dark text-sm font-medium flex items-center gap-x-2">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <span
                    className="flex justify-center items-center rounded-full w-7 h-7 text-xs font-medium hover:cursor-default"
                    style={{ backgroundColor: bgColor, color: textColor }}
                  >
                    {getNameAbbreviation(name)}
                  </span>
                </TooltipTrigger>
                <TooltipContent side="bottom" sideOffset={10}>
                  <p className="relative">
                    <span className="" /> {email}
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </span>
        )
      },
    },
    {
      accessorKey: 'creationDate',
      header: ({ column }) => {
        return (
          <div className="flex items-center gap-x-2">
            Date
            <SortIcon
              className="hover:cursor-pointer"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            />
          </div>
        )
      },
      cell: ({ row }) => {
        const formattedDate = formatDateTime(
          new Date(row.getValue('creationDate'))
        )
        const date = (formattedDate as string).split(' ')[0]
        const time = (formattedDate as string).split(' ')[1]
        return (
          <div className="flex flex-col">
            <span className="text-primary-dark font-normal text-sm">
              {date}
            </span>
            <span className="text-neutral-darker font-normal text-sm">
              {time}
            </span>
          </div>
        )
      },
    },
    {
      accessorKey: 'id',
      header: ({ column }) => {
        return (
          <div className="flex items-center gap-x-2">
            Ticket Jira
            <SortIcon
              className="hover:cursor-pointer"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            />
          </div>
        )
      },
      cell: ({ row }) => {
        if (row.getValue('id')) {
          return (
            <span
              onClick={() => navigate(`/request/${row.getValue('id')}`)}
              className="text-sm font-semibold bg-neutral-light text-neutral-darker/80 hover:text-neutral-darker/100 rounded-full px-3 py-2 underline hover:cursor-pointer"
            >
              {row.getValue('id')}
            </span>
          )
        }
        return <NotAllowedIcon />
      },
    },
    {
      accessorKey: 'status',
      header: ({ column }) => {
        return (
          <div className="flex items-center gap-x-2">
            Statut
            <SortIcon
              className="hover:cursor-pointer"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            />
          </div>
        )
      },
      cell: ({ row }) => {
        const { bgColor } = generateRandomAvatarColor(
          'color_offset_pre' + row.getValue('status')
        )
        const { bgColor: bgColorTransparency } = generateRandomAvatarColor(
          'color_offset_pre' + row.getValue('status'),
          0.15
        )
        return (
          <span className="text-sm font-medium flex items-center gap-x-2">
            <span
              className="flex uppercase justify-center items-center text-sm font-medium px-3 py-1 rounded-md"
              style={{ backgroundColor: bgColorTransparency, color: bgColor }}
            >
              {row.getValue('status')}
            </span>
          </span>
        )
      },
    },
    {
      accessorKey: 'action',
      id: 'action',
      enableHiding: false,
      header: '',
      cell: ({ row }) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="ghost"
                className="h-8 w-8 p-0 border border-neutral-dark rounded-lg"
              >
                <span className="sr-only">Open menu</span>
                <VerticalDot />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="border-neutral-dark w-52 shadow-sm p-4 [&>div]:py-1 [&>div]:px-2 flex flex-col gap-2"
            >
              <DropdownMenuItem
                onClick={() => setCurrentRequestKey(row.getValue('action'))}
              >
                <span className="flex gap-2 text-base font-normal items-center">
                  <EyeOpenIcon /> Voir
                </span>
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  /**
                   * @TODO handle redirect when id dont exist
                   */
                  navigator.clipboard.writeText(
                    `${window.location.origin}/request/${row.getValue('action')}`
                  )
                  toast('Lien copié dans le presse-papiers')
                }}
              >
                <span className="flex gap-2 text-base font-normal items-center">
                  <ClipboardCopyIcon /> Copier le lien
                </span>
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() =>
                  setCurrentRequestKeyToEdit(row.getValue('action'))
                }
              >
                <span className="flex gap-2 text-base font-normal items-center">
                  <PenIcon /> Modifier
                </span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )
      },
    },
  ]
  const [_, setRequestAtom] = useAtom(RequestAtom)
  const [currentRequestKey, setCurrentRequestKey] = useState<string>()
  const [currentRequestKeyToEdit, setCurrentRequestKeyToEdit] =
    useState<string>()
  const [requestList, setRequestList] = useState<RequestListType[]>([])
  const [apiResult, setApiResut] = useState<IProjectRequest[]>()
  const navigate = useNavigate()
  const [editFormAction, setEditFormAction] = useState<{
    title: string
    isEdit: boolean
  }>()

  useEffect(() => {
    const filteredRequests = filterCurrentRequest(
      currentRequestKey || (currentRequestKeyToEdit as string)
    )
    setRequestAtom(filteredRequests as IProjectRequest)
  }, [currentRequestKey, currentRequestKeyToEdit])

  async function getProjectRequests() {
    const { data } = await getProjectRequest()
    return Promise.resolve(data)
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await getProjectRequests()
        setApiResut(result)
        setRequestList((prev) => [
          ...prev,
          ...result.map((item: IProjectRequest) => ({
            id: item.data.jira_issue_id || '',
            subject: `${item.title}__split__${item.category}`,
            creationDate: item.created_at,
            status: item.status,
            author: `${item.submitted_by.first_name} ${item.submitted_by.last_name} ${item.submitted_by.email}`,
            id_subject: `${item.data.jira_issue_id || ''} ${item.title}`,
            action: item.id,
          })),
        ])
      } catch (error) {
        throw new Error('Unexpected error occured')
      }
    }

    fetchData()
  }, [])

  function filterCurrentRequest(key: string): IProjectRequest | undefined {
    return apiResult?.find((item) => key === item.id)
  }

  return (
    <>
      <DataTable editFormAction={editFormAction} columns={columns} data={requestList} apiResult={apiResult} />
      <Dialog
        open={!!currentRequestKey}
        onOpenChange={() => {
          setCurrentRequestKey(undefined), setCurrentRequestKeyToEdit(undefined)
        }}
      >
        <DialogContent className="max-w-[768px]">
          <RequestDetails />
        </DialogContent>
      </Dialog>
      <Dialog
        open={!!currentRequestKeyToEdit}
        onOpenChange={() => setCurrentRequestKeyToEdit(undefined)}
      >
        <DialogContent className="max-w-[640px] pb-0">
          <EditRequestForm
            closeModal={(params?: {
              title: string
              isEdit: boolean
            }) => {
              setCurrentRequestKeyToEdit(undefined)
              setEditFormAction(params)
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
