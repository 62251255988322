import { CheckIcon, ExclamationTriangleIcon } from '@radix-ui/react-icons'

export const CATEGORY_LIST = [
  {
    value: 'Tâche',
    label: 'Tâche',
  },
  {
    value: 'Nouveauté',
    label: 'Nouveauté',
  },
  {
    value: 'Bug',
    label: 'Bug',
  },
  {
    value: 'Amélioration',
    label: 'Amélioration',
  },
  {
    value: 'Feedback',
    label: 'Feedback',
  },
  {
    value: 'Étude',
    label: 'Étude',
  },
  {
    value: 'Risque',
    label: 'Risque',
  },
]

export const TOAST_ERROR_MSG: any = {
  description: (
    <div className="flex items-center gap-2 text-base">
      <ExclamationTriangleIcon width={28} height={28} />
      Une erreur est survenue!
    </div>
  ),
  variant: 'destructive',
}

export const toastSuccessMsg: any = (msg: string) => ({
  description: (
    <div className="flex items-center gap-2 text-base">
      <CheckIcon color="green" width={28} height={28} />
      {msg}
    </div>
  ),
})

export const TAB_LABELS = {
  TO_TEST: 'À tester',
  REFUSED: 'Refusés',
  ACCEPTED: 'Acceptés',
} as const

export const PRIORITY_STATUS: any = {
  Urgent: 'Highest',
  Forte: 'High',
  Moyenne: 'Medium',
  Faible: 'Low',
} as const
