import { useEffect, useRef, useState } from 'react'
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
  SortingState,
  getPaginationRowModel,
  getSortedRowModel,
} from '@tanstack/react-table'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import HeadingTitle from '../ui/heading-title'
import { Input } from '../ui/input'
import SearchIcon from '../icons/SearchIcon'
import { Button } from '../ui/button'
import ChevronLeft from '../icons/ChevronLeft'
import ChevronRight from '../icons/ChevronRight'
import PlusIcon from '../icons/PlusIcon'
import FilterTabs from './FilterTabs'
import { ProjectRequest } from '@/types/IProjectRequest'
import { Dialog, DialogContent } from '../ui/dialog'
import RequestForm from './request-form/form'
import AppAlert from '../ui/app-alert'

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  apiResult?: ProjectRequest[]
  editFormAction?: { title: string; isEdit: boolean }
}

export function DataTable<TData, TValue>({
  columns,
  data,
  apiResult,
  editFormAction,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const tabelRowRef = useRef()
  const [isOpenFormModal, openFormModal] = useState(false)
  const [isDisplaySuccessMsg, setDisplaySuccessMsg] = useState(false)
  const [incomingNewTitle, setIncomingNewTitle] = useState('')
  const [incomingNewAction, setIncomingNewAction] = useState('ajouté')

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters,
    },
  })

  useEffect(() => {
    if (editFormAction?.isEdit)
      handleFormAction(editFormAction.title, editFormAction.isEdit)
  }, [editFormAction])

  const getPageNumbers = () => {
    const pageCount = table.getPageCount() // Total number of pages
    const pageIndex = table.getState().pagination.pageIndex + 1
    const totalPages = pageCount
    const delta = 2 // Number of pages around the current page

    const range = []
    for (
      let i = Math.max(2, pageIndex - delta);
      i <= Math.min(totalPages - 1, pageIndex + delta);
      i++
    ) {
      range.push(i)
    }

    if (pageIndex - delta > 2) {
      range.unshift('...')
    }
    if (pageIndex + delta < totalPages - 1) {
      range.push('...')
    }

    range.unshift(1) // Always show the first page
    if (totalPages > 1) {
      range.push(totalPages) // Always show the last page
    }

    return range
  }

  useEffect(() => {
    table.getColumn('id_subject')?.toggleVisibility(false)
  }, [])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const tableElement = document.getElementsByTagName('table')
      if (tableElement && !tableElement[0].contains(event.target as Node)) {
        table.resetRowSelection()
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [table])

  const handleFilterStatus = (filter: string) => {
    if (filter === 'Tous') {
      table.resetColumnFilters()
    } else {
      table.getColumn('status')?.setFilterValue(filter)
    }
  }

  const handleFormAction = (newTitle: string, isEdit?: boolean) => {
    openFormModal(false)
    if (isEdit) {
      setIncomingNewAction('modifié')
    }
    if (newTitle) {
      setDisplaySuccessMsg(true)
      setIncomingNewTitle(newTitle)
    }
  }

  return (
    <>
      <div>
        <div className="flex justify-between">
          <HeadingTitle label="Demandes" className="mb-0" />
          <Button
            onClick={() => openFormModal(true)}
            className="w-auto flex gap-2"
          >
            <PlusIcon /> Ajouter une demande
          </Button>
        </div>
        {isDisplaySuccessMsg ? (
          <AppAlert
            handleClose={() => setDisplaySuccessMsg(false)}
            variant="success"
          >
            <span className="font-semibold">
              La demande #{incomingNewTitle}
            </span>{' '}
            a été {incomingNewAction}
          </AppAlert>
        ) : (
          ''
        )}
        <div className="flex items-center pt-6 pb-4 justify-between">
          {/* <FilterTabs
            rawData={apiResult as ProjectRequest[]}
            setFilterStatus={handleFilterStatus}
          /> */}
          <Input
            placeholder="Rechercher par sujet ou ID"
            value={
              (table.getColumn('id_subject')?.getFilterValue() as string) ?? ''
            }
            onChange={(event) =>
              table.getColumn('id_subject')?.setFilterValue(event.target.value)
            }
            className="placeholder:text-[#717171] max-w-[275px] placeholder:font-medium text-sm bg-white ml-1"
            prefixIcon={<SearchIcon />}
          />
        </div>
        <div className="border border-neutral-dark rounded-sm [&>div]:rounded-sm">
          <Table>
            <TableHeader className="bg-primary-dark [&_th]:text-xs [&_th]:p-4 [&_th]:text-light [&_tr]:hover:bg-primary-dark border border-primary-dark">
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    )
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody className="bg-white [&_td]:p-3">
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    ref={tabelRowRef as any}
                    key={row.id}
                    data-state={row.getIsSelected() && 'selected'}
                    onClick={() => {
                      table.resetRowSelection()
                      row.toggleSelected()
                    }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    Pas de tickets.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        {/* Pagination Section */}
        <div className="flex items-center justify-between space-x-2 py-4">
          <p className="text-neutral-darker text-sm font-normal">
            {data.length} éléments
          </p>
          <div className="flex items-center justify-end space-x-2">
            <Button
              variant="outline"
              className="w-8 h-8 p-0"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <ChevronLeft />
            </Button>

            {/* Page numbers */}
            {getPageNumbers().map((page, index) =>
              typeof page === 'string' ? (
                <span
                  key={index}
                  className="w-8 h-8 p-0 flex items-center justify-center"
                >
                  {page}
                </span>
              ) : (
                <Button
                  key={index}
                  variant={
                    table.getState().pagination.pageIndex + 1 === page
                      ? 'default'
                      : 'outline'
                  }
                  className="w-8 h-8 p-0"
                  onClick={() => table.setPageIndex(page - 1)}
                >
                  {page}
                </Button>
              )
            )}

            <Button
              variant="outline"
              className="w-8 h-8 p-0"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <ChevronRight />
            </Button>
          </div>
        </div>
      </div>
      <Dialog
        open={!!isOpenFormModal}
        onOpenChange={() => openFormModal(false)}
      >
        <DialogContent className="max-w-[640px] pb-0">
          <RequestForm closeModal={handleFormAction} />
        </DialogContent>
      </Dialog>
    </>
  )
}
