import { useEffect, useState } from 'react'
import { Button } from '../ui/button'
import HomeIcon from '@/components/icons/Home'
import { NavLink } from 'react-router-dom'
import SwitchButton from '../ui/switch-button'
import SprintInProgress from '../icons/SprintInProgress'
import SprintPast from '../icons/SprintPast'
import TimeTracking from '../icons/TimeTracking'
import MessageIcon from '../icons/Message'
import TicketToTest from '../icons/TicketToTest'
import PlusIcon from '../icons/PlusIcon'
import RequestIcon from '../icons/Request'
import ServerIcon from '../icons/Server'
import { getTotalTicketToTest } from '@/services/api-client'

interface IMenuItem {
  title: string
  icon: JSX.Element
  path: string
  name?: string
}

const ACTIVE_MENU_CLASS =
  " flex justify-between bg-neutral-light relative before:contents[''] before:rounded-e-sm before:absolute before:w-1 before:h-6 before:bg-primary-light before:left-0"

const TOP_MENU_LIST: IMenuItem[] = [
  {
    title: 'Accueil',
    icon: <HomeIcon />,
    path: '/',
  },
  // {
  //   title: 'Notifications',
  //   icon: <HomeIcon />,
  //   path: '/notifications',
  // },
  {
    title: 'Messages',
    icon: <MessageIcon />,
    path: '/messages',
  },
  {
    title: 'Demandes',
    icon: <RequestIcon />,
    path: '/demandes',
  },
]

const BOTTOM_MENU_LIST_DELIVERY: IMenuItem[] = [
  {
    title: 'Tickets à tester',
    icon: <TicketToTest />,
    path: '/tickets-a-tester',
    name: 'to-test',
  },
  {
    title: 'Sprint en cours',
    icon: <SprintInProgress />,
    path: '/sprint-en-cours',
  },
  {
    title: 'Sprint passés',
    icon: <SprintPast />,
    path: '/sprint-passe',
  },
  {
    title: 'Backlog',
    icon: <HomeIcon />,
    path: '/backlog',
  },
  {
    title: 'Suivi des temps',
    icon: <TimeTracking />,
    path: '/suivi-des-temps',
  },
]

const BOTTOM_MENU_LIST_MONITORING: IMenuItem[] = [
  {
    title: 'Surveillance serveur',
    icon: <ServerIcon />,
    path: '/surveillance-serveur',
  },
  // {
  //   title: 'État de l’application',
  //   icon: <SprintInProgress />,
  //   path: '/sprint-en-cours',
  // },
  // {
  //   title: 'Performances',
  //   icon: <SprintPast />,
  //   path: '/sprint-passe',
  // },
  // {
  //   title: 'Vulnérabilités',
  //   icon: <HomeIcon />,
  //   path: '/backlog',
  // },
  // {
  //   title: 'Tests unitaires',
  //   icon: <TimeTracking />,
  //   path: '/suivi-des-temps',
  // },
  // {
  //   title: 'Rapports d’erreurs',
  //   icon: <TimeTracking />,
  //   path: '/suivi-des-temps',
  // },
]

export default function SideBar() {
  const [bottomMenuList, setBottomMenuList] = useState<IMenuItem[]>(
    BOTTOM_MENU_LIST_DELIVERY
  )
  const [totalTicketToTest, setTotalTicketToTest] = useState()

  const handleSwitchChange = (status: boolean) => {
    setBottomMenuList(
      status ? BOTTOM_MENU_LIST_DELIVERY : BOTTOM_MENU_LIST_MONITORING
    )
  }
  const fetchTotalTicketToTestData = async () => {
    try {
      const { data } = await getTotalTicketToTest('CLI')
      setTotalTicketToTest(data.total)
    } catch (error) {
      throw new Error(`Unexpected error occured ${error}`)
    }
  }
  useEffect(() => {
    fetchTotalTicketToTestData()
  }, [])
  return (
    <div className="min-w-[304px] border border-neutral-dark h-full">
      {/* LOGO */}
      <div className="flex items-center py-6 pl-8 gap-x-4 border-b border-b-neutral-dark">
        <img src="/img/eskimoz-img.webp" />
        <p className="text-primary-dark font-semibold text-xl">Eskimoz</p>
      </div>

      {/* ADD REQUEST */}
      <div className="px-8 py-6">
        <Button className="w-full flex gap-2">
          <PlusIcon /> Ajouter une demande
        </Button>
      </div>

      {/* TOP MENU */}
      <ul className="pb-6 border-b border-b-neutral-dark [&>li>a]:transition-all [&>li>a]:hover:cursor-pointer  px-8 flex flex-col gap-2 [&>li>a]:flex [&>li>a]:gap-x-2 [&>li>a]:items-center [&>li>a]:p-2 [&>li>a>span]:text-sm [&>li>a>span]:text-primary-dark [&>li>a>span]:font-medium">
        {TOP_MENU_LIST.map((menuItem) => (
          <li
            key={menuItem.title}
            title={menuItem.title}
            className="hover:bg-neutral-light"
          >
            <NavLink
              to={menuItem.path}
              className={({ isActive }) => (isActive ? ACTIVE_MENU_CLASS : '')}
            >
              <div className="inline-flex gap-2">
                {menuItem.icon}
                <span>{menuItem.title}</span>
              </div>
            </NavLink>
          </li>
        ))}
      </ul>

      {/* BOTTOM MENU */}
      <div className="pt-6 px-8 mb-4">
        <SwitchButton onChange={handleSwitchChange} />
      </div>

      <ul className="pb-6 [&>li>a]:transition-all [&>li>a]:hover:cursor-pointer  px-8 flex flex-col gap-2 [&>li>a]:flex [&>li>a]:gap-x-2 [&>li>a]:items-center [&>li>a]:p-2 [&>li>a>span]:text-sm [&>li>a>span]:text-primary-dark [&>li>a>span]:font-medium">
        {bottomMenuList.map((menuItem) => (
          <li
            key={menuItem.title}
            title={menuItem.title}
            className="hover:bg-neutral-light"
          >
            <NavLink
              to={menuItem.path}
              className={({ isActive }) =>
                isActive ? ACTIVE_MENU_CLASS : 'flex justify-between'
              }
            >
              <div className="inline-flex gap-2">
                {menuItem.icon}
                <span>{menuItem.title}</span>
              </div>
              {menuItem.name === 'to-test' && totalTicketToTest !== 0 && (
                <span className="rounded-sm w-5 h-5 inline-flex items-center justify-center bg-third">
                  {totalTicketToTest}
                </span>
              )}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  )
}
