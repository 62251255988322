import clsx from 'clsx'
import { ReactNode } from 'react'
import CrossIcon from '../icons/CrossIcon'

interface Props {
  variant: 'success' | 'danger'
  children: ReactNode
  handleClose: any
}

export default function AppAlert({ variant, children, handleClose }: Props) {
  const variantClass = {
    success: 'before:bg-success bg-success/10',
    danger: 'before:bg-red-600 bg-red-600/10 ',
  }
  return (
    <div
      className={clsx(
        variantClass[variant],
        "py-4 px-8 relative before:absolute before:contents[' '] before:h-full before:w-2 before:top-0 before:left-0"
      )}
    >
      {children}
      <span onClick={handleClose} className='absolute right-4 top-1/2 -translate-y-1/2 hover:cursor-pointer'>
        <CrossIcon />
      </span>
    </div>
  )
}
