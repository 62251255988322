import NotAllowedIcon from "../icons/NotAllowedIcon";

export default function EmptyCommpents() {
  return (
    <div className="h-full flex items-center justify-center flex-col gap-y-2">
      <NotAllowedIcon className="h-7 w-7" />
      <p className="font-medium text-neutral-darker text-center">
        Commentaire vide
      </p>
    </div>
  )
}
