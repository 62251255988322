import { useForm } from 'react-hook-form'
import { ScrollArea } from '../ui/scroll-area'
import { Separator } from '../ui/separator'
import { Textarea } from '../ui/textarea'
import CommentItem from './CommentItem'
import { FormControl, FormField, FormItem, Form } from '../ui/form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from '../ui/button'
import Paperclip from '../icons/Paperclip'
import { toast } from 'sonner'
import EmptyCommpents from './EmptyCommpents'
import { useEffect, useState } from 'react'
import { Loader } from 'lucide-react'
import { getRequestComments } from '@/services/api-client'
import { IComment } from '@/types/IComment'
import CommentForm from './CommentForm'

const formSchema = z.object({
  content: z.string().min(1, { message: '' }),
})

interface Props {
  requestId: string
}
export default function Comments({ requestId }: Props) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  })

  const [isLoadComments, setIsLoadComments] = useState(true)
  const [commentsList, setCommentsList] = useState<IComment[]>([])

  useEffect(() => {
    if (requestId) {
      fetchCommentsList()
    }
  }, [requestId])

  async function fetchCommentsList() {
    try {
      const { data } = await getRequestComments(
        '79543c33-0564-46b7-a90b-b13996832f65'
      )
      setCommentsList(data)
      setIsLoadComments(false)
    } catch (error) {
      throw new Error('Unexpected error occured')
    }
  }

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      /**
       * @TODO handle add comments
       */
      console.log(values)
      /**
       * FOR TEST ONLY
       * RESPONSE DATA TYPE NEED TO MATCH WITH COMMENTSliST DATATYPE[]
       */
      setCommentsList((prev) => [
        ...prev,
        { ...prev[0], id: new Date().toString(), ...values },
      ])
      /** */
      form.reset()
    } catch (error) {
      throw new Error('Unexpected error occured')
    }
  }
  function onInvalid() {
    toast('Un commentaire ne doit pas être vide!')
  }

  return (
    <div className="w-full">
      <p className="pb-5 font-medium text-base text-primary-dark">
        Commentaires
      </p>
      <Separator className="absolute left-0" />
      {/* COMMENT ITEM */}
      <ScrollArea className="h-[76vh] pb-[144px]">
        <div className="h-full pr-2">
          {isLoadComments ? (
            <Loader />
          ) : (
            <>
              {commentsList.length ? (
                commentsList.map((comments) => (
                  <CommentItem key={comments.id} comments={comments} />
                ))
              ) : (
                <EmptyCommpents />
              )}
            </>
          )}
        </div>
      </ScrollArea>
      <CommentForm form={form} onSubmit={onSubmit} onInvalid={onInvalid} />
    </div>
  )
}
