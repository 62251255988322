import CustomCard from '@/components/ui/custom-card'
import HeadingTitle from '@/components/ui/heading-title'
import DonutChart from '@/components/dashboard/DonutChart'
import TaskStatusList from '@/components/dashboard/TaskStatusList'
import ClockIcon from '@/components/icons/Clock'
import TaskListIcon from '@/components/icons/TaskList'
import TicketCard from '@/components/dashboard/TicketCard'
import SprintDuration from '@/components/dashboard/SprintDuration'
import UsefulLinks from '@/components/dashboard/UsefulLinks'
import { useState, useEffect } from 'react'
import { getDashboardData } from '@/services/api-client'
import { chartConfig } from '@/configs/chartConfig'
import Release from '@/components/dashboard/Release'
import { IDashboard, ReleaseNotes, Link, TeamMember } from '@/types/IDashboard'
import InterlocutorList from '@/components/dashboard/Interlocutor'

interface IChartDataItem {
  key: string
  value: number
  fill: string
  name: string
}
interface ITaskItem {
  icon: JSX.Element
  count: number
  label: string
  name: string
}

export default function Dashboard() {
  const [dashboard, setDashboard] = useState<IDashboard | null>()
  const [chartData, setChartData] = useState<IChartDataItem[]>([
    { key: 'todo', value: 0, fill: '#1F73E0', name: 'todo_count' },
    {
      key: 'inProgress',
      value: 0,
      fill: '#1F73E099',
      name: 'in_progress_count',
    },
    { key: 'done', value: 0, fill: '#1F73E033', name: 'done_count' },
  ])
  const [taskList, setTaskList] = useState<ITaskItem[]>([
    {
      icon: <TaskListIcon />,
      count: 0,
      label: 'A faire',
      name: 'todo_count',
    },
    {
      icon: <ClockIcon />,
      count: 0,
      label: 'En cours',
      name: 'in_progress_count',
    },
    {
      icon: <ClockIcon />,
      count: 0,
      label: 'Terminés',
      name: 'done_count',
    },
  ])

  useEffect(() => {
    fetchDashboardData()
  }, [])

  const fetchDashboardData = async () => {
    try {
      const { data } = await getDashboardData('CLI')
      setDashboard(data)

      setChartData((prev) =>
        prev.map((item) => ({ ...item, value: data[item.name] }))
      )

      setTaskList((prev) =>
        prev.map((item) => ({ ...item, count: data[item.name] }))
      )
    } catch (error) {
      throw new Error('Unexpected error occured')
    }
  }

  return (
    <div className="h-full">
      <HeadingTitle label="Bonjour Lucie," />
      <div className="flex flex-col gap-4 h-[calc(100%-3.5rem)]">
        <div className="grid grid-cols-6 gap-4">
          <CustomCard title="Sprint actuels" className="col-span-4">
            <div className="flex p-7 pt-0 gap-6">
              <div className="grid grid-cols-2 items-center flex-1">
                <div className="[&>div]:max-h-[210px]">
                  <DonutChart
                    data={chartData}
                    config={chartConfig}
                    label={{
                      title: dashboard?.tasks_count?.toString() || '0',
                      subtitle: 'Tâches',
                    }}
                  />
                </div>
                <TaskStatusList items={taskList} />
              </div>
              <div>
                <SprintDuration
                  className="mb-5"
                  startDate={dashboard?.sprint_start_date as string}
                  endDate={dashboard?.sprint_end_date as string}
                />
                <TicketCard
                  tickets={dashboard?.to_be_tested_count?.toString() || '0'}
                  label="Tickets à tester"
                />
              </div>
            </div>
          </CustomCard>
          <CustomCard title="Liens utiles" className="col-span-2">
            <div className="p-7 pt-0">
              <UsefulLinks links={dashboard?.links as Link[]} />
            </div>
          </CustomCard>
        </div>
        <div
          className="grid grid-cols-6 h-full gap-4
        "
        >
          <CustomCard title="Mes interlocuteurs" className="col-span-2">
            <div className="pl-8 pr-0 pb-6">
              <InterlocutorList
                manager={dashboard?.project_manager as TeamMember}
                devops={dashboard?.devops as TeamMember[]}
                owners={dashboard?.product_owners as TeamMember[]}
              />
            </div>
          </CustomCard>
          <CustomCard title="Notes de version" className="col-span-2">
            <div className="px-8 pb-6">
              <Release releases={dashboard?.releases as ReleaseNotes[]} />
            </div>
          </CustomCard>
        </div>
      </div>
    </div>
  )
}
