import TaskStatusItem from '@/components/dashboard/TaskStatusItem'

interface TaskStatusListProps {
  items: {
    icon: React.ReactElement
    count: number
    label: string
  }[]
}

const TaskStatusList: React.FC<TaskStatusListProps> = ({ items }) => {
  return (
    <div className="flex flex-col gap-5 flex-1">
      {items.map((item, index) => (
        <TaskStatusItem key={index} {...item} />
      ))}
    </div>
  )
}

export default TaskStatusList
