import clsx from 'clsx'
import React from 'react'

interface HeadingTitleProps {
  label: string,
  className?: string
}
const HeadingTitle: React.FC<HeadingTitleProps> = ({ label, className }) => {
  return <h1 className={clsx("font-semibold text-2xl mb-6", className)}>{label}</h1>
}

export default HeadingTitle
