import { useEffect, useState } from 'react'
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
  SortingState,
  getPaginationRowModel,
  getSortedRowModel,
} from '@tanstack/react-table'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import HeadingTitle from '../ui/heading-title'
import { Input } from '../ui/input'
import SearchIcon from '../icons/SearchIcon'
import { Button } from '../ui/button'
import ChevronLeft from '../icons/ChevronLeft'
import ChevronRight from '../icons/ChevronRight'

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
}

export function DataTable<TData, TValue>({
  columns,
  data,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters,
    },
  })

  const getPageNumbers = () => {
    const pageCount = table.getPageCount() // Total number of pages
    const pageIndex = table.getState().pagination.pageIndex + 1
    const totalPages = pageCount
    const delta = 2 // Number of pages around the current page

    const range = []
    for (
      let i = Math.max(2, pageIndex - delta);
      i <= Math.min(totalPages - 1, pageIndex + delta);
      i++
    ) {
      range.push(i)
    }

    if (pageIndex - delta > 2) {
      range.unshift('...')
    }
    if (pageIndex + delta < totalPages - 1) {
      range.push('...')
    }

    range.unshift(1) // Always show the first page
    if (totalPages > 1) {
      range.push(totalPages) // Always show the last page
    }

    return range
  }

  useEffect(() => {
    table.getColumn('id_subject')?.toggleVisibility(false)
  }, [])

  return (
    <div>
      <HeadingTitle label="Backlog" className="mb-0" />
      <div className="flex items-center pt-6 pb-4">
        <Input
          placeholder="Rechercher par sujet ou clé"
          value={
            (table.getColumn('id_subject')?.getFilterValue() as string) ?? ''
          }
          onChange={(event) =>
            table.getColumn('id_subject')?.setFilterValue(event.target.value)
          }
          className="placeholder:text-[#717171] max-w-[275px] placeholder:font-medium text-sm bg-white"
          prefixIcon={<SearchIcon />}
        />
      </div>
      <div className="border border-neutral-dark rounded-sm [&>div]:rounded-sm">
        <Table>
          <TableHeader className="bg-primary-dark [&_th]:text-xs [&_th]:p-4 [&_th]:text-light [&_tr]:hover:bg-primary-dark border border-primary-dark">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className="bg-white [&_td]:p-3">
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  Pas de tickets.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      {/* Pagination Section */}
      <div className="flex items-center justify-end space-x-2 py-4">
        <Button
          variant="outline"
          className="w-8 h-8 p-0"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <ChevronLeft />
        </Button>

        {/* Page numbers */}
        {getPageNumbers().map((page, index) =>
          typeof page === 'string' ? (
            <span
              key={index}
              className="w-8 h-8 p-0 flex items-center justify-center"
            >
              {page}
            </span>
          ) : (
            <Button
              key={index}
              variant={
                table.getState().pagination.pageIndex + 1 === page
                  ? 'default'
                  : 'outline'
              }
              className="w-8 h-8 p-0"
              onClick={() => table.setPageIndex(page - 1)}
            >
              {page}
            </Button>
          )
        )}

        <Button
          variant="outline"
          className="w-8 h-8 p-0"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <ChevronRight />
        </Button>
      </div>
    </div>
  )
}
