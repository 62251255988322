import HeadingTitle from '@/components/ui/heading-title'
import TicketSummaryItem from '@/components/ticket-to-test/TicketSummaryItem'
import TicketSummaryDetail from '@/components/ticket-to-test/TicketSummaryDetail'
import ChevronLeft from '@/components/icons/ChevronLeft'
import ChevronRight from '@/components/icons/ChevronRight'
import FilterTabs from '@/components/request/FilterTabs'
import { useTicketStats, STATUS } from '@/hooks/use-ticket-stats'
import { TAB_LABELS, PRIORITY_STATUS } from '@/configs/constants'
import { getTicketToTest } from '@/services/api-client'
import { Button } from '@/components/ui/button'
import { IIssue } from '@/types/ITicketToTest'
import { useEffect, useState } from 'react'
import SearchIcon from '@/components/icons/SearchIcon'
import { Input } from '@/components/ui/input'
import SortIcon from '@/components/icons/SortIcon'
import NotAllowedIcon from '@/components/icons/NotAllowedIcon'
import ActiveFilterTag from '@/components/ticket-to-test/ActiveFilterTag'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Checkbox } from '@/components/ui/checkbox'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

const options = [
  { id: 'Urgent', label: 'Urgent' },
  { id: 'Forte', label: 'Forte' },
  { id: 'Moyenne', label: 'Moyenne' },
  { id: 'Faible', label: 'Faible' },
]

export default function TicketToTest() {
  const [currentPage, setCurrentPage] = useState(1)
  const [ticketList, setTicketList] = useState<IIssue[]>([])
  const [activeTicketId, setActiveTicketId] = useState<string | null>()
  const [ticketDetail, setTicketDetail] = useState<IIssue>()
  const [ticketToTest, setTicketToTest] = useState<number>(0)
  const [ticketRefused, setTicketRefused] = useState<number>(0)
  const [ticketAccepted, setTicketAccepted] = useState<number>(0)
  const [currentTab, setCurrentTab] = useState<string>('')
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [dateSort, setDateSort] = useState<string>('')
  const [currentTickets, setCurrentTickets] = useState<IIssue[]>()
  const [selectedPriorities, setSelectedPriorities] = useState<string[]>([])
  const stats = useTicketStats(ticketList)
  const itemsPerPage = 6

  const fetchTicketToTestData = async () => {
    try {
      const { data } = await getTicketToTest('CLI')
      setTicketList(data)
      if (data && data.length > 0) {
        setActiveTicketId(data[0].key)
        setTicketDetail(data[0])
      }
    } catch (error) {
      throw new Error('Unexpected error occured')
    }
  }

  const filterTickets = () => {
    let filteredList = ticketList

    // apply filter tab
    if (currentTab) {
      switch (currentTab) {
        case TAB_LABELS.ACCEPTED:
          filteredList = filteredList.filter(
            (item) => item.fields.status.name === STATUS.accepted
          )
          break
        case TAB_LABELS.REFUSED:
          filteredList = filteredList.filter(
            (item) => item.fields.status.name === STATUS.refused
          )
          break
        case TAB_LABELS.TO_TEST:
          filteredList = filteredList.filter(
            (item) => item.fields.status.name === STATUS.toTest
          )
          break
      }
    }

    // apply search input
    if (searchQuery) {
      filteredList = filteredList.filter(
        (item) =>
          item.key.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.fields.summary.toLowerCase().includes(searchQuery.toLowerCase())
      )
    }

    // Apply priority filter
    if (selectedPriorities.length > 0) {
      const enSelectedPrioritites = selectedPriorities.map((item) =>
        PRIORITY_STATUS[item].toLowerCase()
      )

      filteredList = filteredList.filter((item) =>
        enSelectedPrioritites.includes(item.fields.priority.name.toLowerCase())
      )
    }

    // Apply date sort
    if (dateSort) {
      filteredList.sort((a, b) => {
        const dateA = new Date(a.fields.created).getTime()
        const dateB = new Date(b.fields.created).getTime()
        return dateSort === 'desc' ? dateB - dateA : dateA - dateB
      })
    }

    // Update the current ticket and paginator
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    setCurrentTickets(filteredList.slice(startIndex, endIndex))

    // If the filtered list is not empty, select the first ticket
    if (filteredList.length > 0) {
      const firstVisibleTicket = filteredList[startIndex]
      setActiveTicketId(firstVisibleTicket.key)
      setTicketDetail(firstVisibleTicket)
    }
  }

  const handleTabFilter = (key: string) => {
    setCurrentTab(key)
    setCurrentPage(1) // Reset page when switching tabs
  }

  const handlePriorityChange = (priority: string) => {
    setSelectedPriorities((prev) => {
      if (prev.includes(priority)) {
        return prev.filter((p) => p !== priority)
      }
      return [...prev, priority]
    })
    setCurrentPage(1)
  }

  const handleDateSortChange = (value: string) => {
    setDateSort(value)
  }
  const handleFilterBySubjetOrKey = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value)
    setCurrentPage(1) //Reset page when searching
  }

  const handleTicketClick = (ticketId: string) => {
    setActiveTicketId(ticketId)
    const ticket = ticketList.find((ticket: IIssue) => ticket.key === ticketId)
    setTicketDetail(ticket)
  }

  const getTotalPages = () => {
    const filteredLength = currentTickets?.length || 0
    return Math.ceil(filteredLength / itemsPerPage)
  }

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1))
  }

  const handleNextPage = () => {
    const totalPages = getTotalPages()
    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
  }

  const applyFilters = () => {
    setCurrentPage(1)
    filterTickets()
  }

  useEffect(() => {
    fetchTicketToTestData()
  }, [])

  useEffect(() => {
    if (ticketList.length > 0) {
      setTicketToTest(
        ticketList.filter((item) => item.fields.status.name === STATUS.toTest)
          .length
      )
      setTicketRefused(
        ticketList.filter((item) => item.fields.status.name === STATUS.refused)
          .length
      )
      setTicketAccepted(
        ticketList.filter((item) => item.fields.status.name === STATUS.accepted)
          .length
      )
    }
  }, [ticketList])

  useEffect(() => {
    filterTickets()
  }, [
    currentTab,
    searchQuery,
    ticketList,
    currentPage,
    selectedPriorities,
    dateSort,
  ])

  useEffect(() => {
    setTicketToTest(stats.toTest)
    setTicketRefused(stats.refused)
    setTicketAccepted(stats.accepted)
  }, [stats])

  const handleRemoveDateSort = () => {
    setDateSort('')
    setCurrentPage(1)
  }

  const handleRemovePriorityFilter = () => {
    setSelectedPriorities([])
    setCurrentPage(1)
  }

  return (
    <div className="flex flex-col h-[calc(100%-3rem)]">
      <HeadingTitle label="Tickets à tester" />

      <div className="mb-4 pb-3 border-b-2 flex items-center justify-between [&_ul]:border-none [&_li]:p-0 [&_li:after]:bottom-[-20px]">
        <FilterTabs
          additionalStatusData={[
            { status: TAB_LABELS.TO_TEST, count: ticketToTest ?? 0 },
            { status: TAB_LABELS.REFUSED, count: ticketRefused ?? 0 },
            { status: TAB_LABELS.ACCEPTED, count: ticketAccepted ?? 0 },
          ]}
          setFilterStatus={handleTabFilter}
        />
        <div className="inline-flex gap-5 items-center">
          <div className="[&_svg]:w-5 [&_svg]:h-5 [&_path]:stroke-primary-dark hover:cursor-pointer">
            <DropdownMenu>
              <DropdownMenuTrigger>
                <SortIcon />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <div className="p-4 flex gap-2 flex-col">
                  <p className="text-base text-primary-dark">Filtrer par</p>
                  <p className="uppercase text-xs text-neutral-darker after:contents[' '] relative after:absolute after:w-[75%] after:right-0 after:block after:h-1 after:bg-neutral-light after:top-1">
                    Priorité
                  </p>

                  {options.map((option) => (
                    <div
                      key={option.id}
                      className="inline-flex items-center gap-2"
                    >
                      <Checkbox
                        id={option.id}
                        checked={selectedPriorities.includes(option.id)}
                        onCheckedChange={() => handlePriorityChange(option.id)}
                      />
                      <label
                        htmlFor={option.id}
                        className="text-primary-dark text-sm"
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}

                  <p className="uppercase text-xs text-neutral-darker after:contents[' '] relative after:absolute after:w-[85%] after:right-0 after:block after:h-1 after:bg-neutral-light after:top-1">
                    Date
                  </p>
                  <div className="pb-2 border-b-2 border-neutral-dark">
                    <Select
                      value={dateSort}
                      onValueChange={handleDateSortChange}
                    >
                      <SelectTrigger className="w-[282px]" isClassic>
                        <SelectValue placeholder="Selectionner un filtre" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="desc">
                          Du plus récent au plus ancien
                        </SelectItem>
                        <SelectItem value="asc">
                          Du plus ancien au plus récent
                        </SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="flex items-center justify-end">
                    <DropdownMenuItem>
                      <Button
                        className="px-4 py-2 text-white max-w-28"
                        onClick={applyFilters}
                      >
                        Appliquer
                      </Button>
                    </DropdownMenuItem>
                  </div>
                </div>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <Input
            placeholder="Rechercher par sujet ou clé"
            onChange={handleFilterBySubjetOrKey}
            value={searchQuery}
            className="placeholder:text-[#717171] h-8 mr-1 max-w-[275px] placeholder:font-medium text-sm bg-white"
            prefixIcon={<SearchIcon />}
          />
        </div>
      </div>
      <div className="flex gap-2 items-center mb-4">
        {dateSort && (
          <ActiveFilterTag
            label={`Date: ${dateSort === 'desc' ? 'Du plus récent au plus ancien' : ' Du plus ancien au plus récent'}`}
            onRemove={() => handleRemoveDateSort()}
          />
        )}
        {selectedPriorities.length > 0 && (
          <ActiveFilterTag
            label={`Priorité: ${selectedPriorities.join(', ')}`}
            onRemove={() => handleRemovePriorityFilter()}
          />
        )}
      </div>

      <div className="grid grid-cols-6 gap-4 flex-grow">
        <div className="col-span-2 rounded-sm overflow-hidden flex flex-col">
          <div className="flex-grow">
            {currentTickets?.map((ticket: IIssue) => (
              <TicketSummaryItem
                key={ticket.id}
                ticketKey={ticket.key}
                created={ticket.fields.created}
                label={ticket.fields.summary}
                isActive={activeTicketId === ticket.key}
                count={ticket.fields.comment.total}
                handleClick={() => handleTicketClick(ticket.key)}
              />
            ))}
            {!currentTickets || currentTickets.length === 0 ? (
              <div className="bg-white rounded-sm border border-neutral-dark flex items-center justify-center h-28">
                <p className="inline-flex gap-3 items-center">
                  <span>
                    <NotAllowedIcon />
                  </span>
                  <span>Pas de résultat</span>
                </p>
              </div>
            ) : (
              ''
            )}
          </div>

          {/* Pagination */}
          <div className="flex justify-between items-center p-4">
            <span className="text-sm text-gray-600">
              Page {currentPage} sur {getTotalPages()}
            </span>
            <div className="inline-flex gap-4">
              <Button
                variant="outline"
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className="w-8 h-8 p-0"
              >
                <ChevronLeft />
              </Button>

              <Button
                variant="outline"
                onClick={handleNextPage}
                disabled={currentPage === getTotalPages()}
                className="w-8 h-8 p-0"
              >
                <ChevronRight />
              </Button>
            </div>
          </div>
        </div>

        <TicketSummaryDetail
          title={ticketDetail?.fields.summary as string}
          priorityStatus={ticketDetail?.fields.priority.name as string}
          ticketKey={ticketDetail?.key as string}
          date={new Date(ticketDetail?.fields.created as string)}
        />
      </div>
    </div>
  )
}
