import ChevronLeft from '@/components/icons/ChevronLeft'
import ChevronRight from '@/components/icons/ChevronRight'
import CrossIcon from '@/components/icons/CrossIcon'
import ExtendIcon from '@/components/icons/ExtendIcon'
import PenIcon from '@/components/icons/PenIcon'
import { DialogClose } from '@/components/ui/dialog'
import { NavLink } from 'react-router-dom'
import { toast } from 'sonner'

export default function ActionsTopFullscreen({ticketKey}: {ticketKey: string}) {
  return (
    <div className="z-50 flex absolute w-full left-0 top-0 h-20 px-6 bg-white justify-between items-center border-b">
      <div className="flex items-center gap-x-2">
        <div className="opacity-40 text-sm transition-opacity hover:opacity-100 data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
          <ChevronLeft />
        </div>
        <div className="opacity-40 text-sm transition-opacity hover:opacity-100 data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
          <ChevronRight />
        </div>
      </div>
      <div className="flex items-center gap-x-2">
        <div
          onClick={() => {
            navigator.clipboard.writeText(
              `${window.location.origin}/request/${ticketKey}`
            )
            toast('Lien copié dans le presse-papiers')
          }}
          className="hover:cursor-pointer opacity-70 text-sm transition-opacity hover:opacity-100 data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
        >
          Copier le lien
        </div>
        <NavLink to={`/request/${ticketKey}`} className="hover:cursor-pointer opacity-70 transition-opacity hover:opacity-100 data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
          <PenIcon className="h-5 w-5" />
        </NavLink>
      </div>
    </div>
  )
}
