import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import DashBoardLayout from '@/layouts/Dashboard'
import Dashboard from '@/pages/dashboard/dashboard'
import SprintInProgress from '@/pages/dashboard/sprint-in-progress'
import SprintPast from '@/pages/dashboard/sprint-past'
import TimeTracking from '@/pages/dashboard/time-tracking'
import Message from '@/pages/dashboard/messages'
import TicketToTest from '@/pages/dashboard/ticket-to-test'
import Backlog from '@/pages/dashboard/backlog'
import Request from '@/pages/dashboard/request'
import Monitoring from '@/pages/dashboard/monitoring'
import FullScreenRequest from '@/pages/dashboard/fullscreen/request/[id]'

const Routing: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<DashBoardLayout />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/sprint-en-cours" element={<SprintInProgress />} />
          <Route path="/sprint-passe" element={<SprintPast />} />
          <Route path="/suivi-des-temps" element={<TimeTracking />} />
          <Route path="/messages" element={<Message />} />
          <Route path="/tickets-a-tester" element={<TicketToTest />} />
          <Route path="/backlog" element={<Backlog />} />
          <Route path="/demandes" element={<Request />} />
          <Route path="/surveillance-serveur" element={<Monitoring />} />
          <Route path="/request/:id" element={<FullScreenRequest />} />
        </Route>
      </Routes>
    </Router>
  )
}

export default Routing
