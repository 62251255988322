import { UseFormReturn } from 'react-hook-form'
import { Form, FormControl, FormField, FormItem } from '../ui/form'
import { Textarea } from '../ui/textarea'
import { Button } from '../ui/button'
import Paperclip from '../icons/Paperclip'

interface Props {
  form: UseFormReturn<any>
  onSubmit: (params: any) => Promise<void>
  onInvalid?: () => void
}

export default function CommentForm({ form, onSubmit, onInvalid }: Props) {
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit, onInvalid)}>
        <FormField
          control={form.control}
          name="content"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <div className="relative w-full">
                  <div className="absolute border bg-neutral-light rounded-md bottom-0 w-full right-0 h-36">
                    <Textarea
                      {...field}
                      value={field.value || ''}
                      placeholder="Ajouter un commentaire ..."
                      className="w-full resize-none border-none shadow-none rounded-md h-24"
                    />
                  </div>
                </div>
              </FormControl>
            </FormItem>
          )}
        />
        <div className="absolute bottom-9 right-10 flex items-center">
          <Button type="button" size={'sm'} variant={'ghost'}>
            <Paperclip />
          </Button>
          <Button size={'lg'} type="submit" className="w-[87px]">
            Envoyer
          </Button>
        </div>
      </form>
    </Form>
  )
}
