import InterlocutorItem from '@/components/dashboard/InterlocutorItem'
import { TeamMember } from '@/types/IDashboard'

interface IInterlocutorListProps {
  manager: TeamMember
  devops: TeamMember[]
  owners: TeamMember[]
}

const InterlocutorList: React.FC<IInterlocutorListProps> = ({
  manager,
  devops = [],
  owners = [],
}) => {
  return (
    <div className="flex flex-col gap-6 max-h-[245px] overflow-auto pr-5">
      <InterlocutorItem
        firstname={manager?.first_name}
        position="project_manager"
        status={manager?.user_profile.availability}
        image={manager?.user_profile.profile_picture}
      />
      {devops.map((interlocutor, index) => (
        <InterlocutorItem
          key={index}
          firstname={interlocutor.first_name}
          position="devops"
          status={interlocutor.user_profile.availability}
          image={interlocutor?.user_profile.profile_picture}
        />
      ))}
      {owners.map((interlocutor, index) => (
        <InterlocutorItem
          key={index}
          firstname={interlocutor.first_name}
          position="product_owners"
          status={interlocutor.user_profile.availability}
          image={interlocutor?.user_profile.profile_picture}
        />
      ))}
    </div>
  )
}

export default InterlocutorList
