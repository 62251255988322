import InclinedArrow from '@/components/icons/InclinedArrow'

interface ITicketCardProps {
  tickets: string
  label: string
}
const TicketCard: React.FC<ITicketCardProps> = ({ tickets, label }) => {
  return (
    <div className="flex flex-1 flex-col justify-between  bg-primary-light rounded p-4">
      <div className="flex justify-end">
        <div className=" rounded cursor-pointer bg-white p-2 mb-6 [&_path]:stroke-primary-light">
          <InclinedArrow />
        </div>
      </div>
      <div>
        <div className="font-semibold text-white text-5xl">{tickets}</div>
        <div className="text-white text-sm ">{label}</div>
      </div>
    </div>
  )
}

export default TicketCard
