import { DialogTitle } from '@/components/ui/dialog'
import ActionsTop from './ActionsTop'
import { DialogDescription } from '@radix-ui/react-dialog'
import { useAtom } from 'jotai'
import { RequestAtom } from '@/atom/request'
import {
  formatDateTime,
  generateRandomAvatarColor,
  getNameAbbreviation,
} from '@/lib/utils'
import { useEffect, useState } from 'react'

function CustomNameAvatar({ rawValue }: { rawValue: string }) {
  if (rawValue) {
    const { bgColor, textColor } = generateRandomAvatarColor(rawValue)
    const firstname = rawValue.split(' ')[0]
    return (
      <span className="text-primary-dark flex items-center gap-x-2 font-normal text-base">
        <span
          className="flex justify-center items-center rounded-full w-7 h-7 text-xs font-medium"
          style={{ backgroundColor: bgColor, color: textColor }}
        >
          {getNameAbbreviation(rawValue)}
        </span>
        {firstname}
      </span>
    )
  } else {
    return null
  }
}

export default function RequestDetails({
  isFullScreen,
}: {
  isFullScreen?: boolean
}) {
  const [requestAtom] = useAtom(RequestAtom)
  const [statusStyle, setStatusStyle] = useState({
    bg: '',
    text: '',
  })
  const [categoryStyle, setCategoryStyle] = useState({
    bg: '',
    text: '',
  })
  useEffect(() => {
    const assignStatusColor = () => {
      const { bgColor: text } = generateRandomAvatarColor(
        'color_offset_pre' + requestAtom?.status
      )
      const { bgColor: bg } = generateRandomAvatarColor(
        'color_offset_pre' + requestAtom?.status,
        0.15
      )
      setStatusStyle(() => ({ text, bg }))
    }

    const assignCategoryColor = () => {
      const { bgColor: text } = generateRandomAvatarColor(
        'color_offset_pre' + requestAtom?.category
      )
      const { bgColor: bg } = generateRandomAvatarColor(
        'color_offset_pre' + requestAtom?.category,
        0.15
      )
      setCategoryStyle(() => ({ text, bg }))
    }
    assignStatusColor()
    assignCategoryColor()
  }, [requestAtom])
  return (
    <div>
      {/* dialog top actions */}
      {!isFullScreen && <ActionsTop ticketKey={requestAtom?.id as string} />}
      {/* dialog main content */}
      <div className="pt-8 pb-6">
        {/* dialog title */}
        {!isFullScreen && (
          <>
            <DialogDescription />
            <DialogTitle />
          </>
        )}
        <div className="w-full grid grid-cols-4">
          <h1 className="text-primary-dark font-semibold w-full max-w-[554px] col-span-3">
            {requestAtom?.title}
          </h1>
          <span className="text-[#717171] font-semibold w-full text-end">
            {requestAtom?.data.jira_issue_id || ''}
          </span>
        </div>
      </div>
      <ul className="pb-6 flex [&>] flex-col gap-y-4 [&>li]:flex [&>li]:w-full [&>li]:justify-between max-w-72 [&>li>span:first-child]:text-base [&>li>span:first-child]:font-normal [&>li>span:first-child]:text-[#717171]">
        <li>
          <span>Demandé par</span>
          <div>
            <CustomNameAvatar
              rawValue={
                requestAtom?.submitted_by.first_name +
                ' ' +
                requestAtom?.submitted_by.last_name
              }
            />
          </div>
        </li>
        <li>
          <span>Catégorie</span>{' '}
          <span className="text-sm font-medium flex items-center gap-x-2">
            <span
              className="flex justify-center items-center text-sm font-medium px-3 py-1 rounded-full"
              style={{
                backgroundColor: categoryStyle.bg,
                color: categoryStyle.text,
              }}
            >
              {requestAtom?.category}
            </span>
          </span>
        </li>
        <li>
          <span>Statut</span>{' '}
          <span className="text-sm font-medium flex items-center gap-x-2">
            <span
              className="flex uppercase justify-center items-center text-sm font-medium px-3 py-1 rounded-md"
              style={{
                backgroundColor: statusStyle.bg,
                color: statusStyle.text,
              }}
            >
              {requestAtom?.status}
            </span>
          </span>
        </li>
        <li>
          <span>Environnement</span>{' '}
          <span className="text-base text-primary-dark">
            {requestAtom?.environment}
          </span>
        </li>
        <li>
          <span>Date de création</span>{' '}
          <span className="text-primary-dark">
            {formatDateTime(new Date(requestAtom?.created_at as string))}
          </span>
        </li>
      </ul>
      {/* separator */}
      <div className="w-full border-b border-b-neutral-dark absolute left-1/2 -translate-x-1/2" />

      {/* description */}
      <div className="pt-6">
        <div className="p-6">
          <div className="text-primary-dark font-normal text-base max-h-52 overflow-y-auto">
            {requestAtom?.summary}
          </div>
        </div>
      </div>
    </div>
  )
}
