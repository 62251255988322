import axios from 'axios'
import Cookies from 'js-cookie'

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
})

axiosClient.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    let res = error.response
    if (res.status == 401) {
      /**
       * @TODO redirect to login
       */
    }
    console.error('Looks like there was a problem. Status Code:' + res.status)
    return Promise.reject(error)
  }
)

/**
 * @TODO append this feat when DEV MODE
 */
axiosClient.defaults.headers.common['Authorization'] =
  `Token ${Cookies.get('apitoken')}`

export default axiosClient
