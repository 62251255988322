import ReleaseItem from '@/components/dashboard/ReleaseItem'
import { ReleaseNotes } from '@/types/IDashboard'

interface ReleasesData {
  releases: ReleaseNotes[]
}

const Release: React.FC<ReleasesData> = ({ releases = [] }) => {
  const isNew = (index: number) => (index === 0 ? true : false)

  return (
    <>
      {releases.map((release, index) => (
        <ReleaseItem
          key={index}
          version={release.number}
          isNew={isNew(index)}
          date={release.modified_at}
        />
      ))}
    </>
  )
}

export default Release
